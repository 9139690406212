import { Navigate, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import LanguageProvider from './LanguageProvider';
import Layout from './Layout';
import SignIn from './SignIn';
import MssPage from './mss/MssPage';
import MessagePage from './message/MessagePage';
import NeedsApproval from './message/NeedsApproval';
import BlockedAccount from './message/BlockedAccount';
import UnknownError from './message/UnknownError';
import TtsPage from './tts/TtsPage';
import NDAPage from './agreements/NDAPage';
import IndexPage from './index/IndexPage';
import { createTheme, ThemeProvider } from '@mui/material';
import SvsPage from './svs/SvsPage';
import TunerPage from './tuner/TunerPage';
import Finetune from './tuner/menu/finetune/FinetunePage';
import History from './tuner/menu/HistoryPage';
import Evaluation from './tuner/menu/EvaluationPage';
import BwePage from './bwe/BwePage';
import AwmPage from './awm/AwmPage';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#021bf5',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <Routes>
          {/* 로그인 페이지 */}
          <Route path="/signin" element={<SignIn />} />

          <Route path="/">
            <Route element={<Layout />}>
              <Route index element={<Navigate to="/tts" replace />} />
              <Route path="mss" element={<MssPage />} />
              <Route path="svs" element={<SvsPage />} />
              <Route path="tts" element={<TtsPage />} />
              <Route path="bwe" element={<BwePage />} />
              <Route path="awm" element={<AwmPage />} />
              <Route path="tuner" element={<TunerPage />}>
                <Route
                  index
                  element={<Navigate to="/tuner/finetune" replace />}
                />
                /* default page */
                <Route path="finetune" element={<Finetune />} />
                <Route path="history" element={<History />} />
                <Route path="evaluation/:id" element={<Evaluation />} />
              </Route>
            </Route>
          </Route>

          <Route path="/message" element={<Layout />}>
            <Route
              path="needs-approval"
              element={
                <MessagePage>
                  <NeedsApproval />
                </MessagePage>
              }
            />
            <Route
              path="blocked-account"
              element={
                <MessagePage>
                  <BlockedAccount />
                </MessagePage>
              }
            />
            <Route
              path="unknown-error"
              element={
                <MessagePage>
                  <UnknownError />
                </MessagePage>
              }
            />
          </Route>

          <Route path="/nda" element={<Layout />}>
            <Route
              path=""
              element={
                <MessagePage>
                  <NDAPage />
                </MessagePage>
              }
            />
          </Route>

          {/* 디폴트 페이지 */}
          <Route index element={<IndexPage />} />
        </Routes>
        <Toaster
          toastOptions={{
            success: {
              duration: 2000,
            },
            error: {
              duration: 3000,
            },
          }}
        />
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
