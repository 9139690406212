import { Card, CardContent, Typography } from '@mui/material';

interface DecodeResultCardProps {
  message: string;
  startIndex: number;
  endIndex: number;
  onDelete: () => void;
}

const DecodeResultCard = ({
  message,
  startIndex,
  endIndex,
  onDelete,
}: DecodeResultCardProps) => {
  return (
    <Card
      sx={{
        margin: '0.5rem 0',
        padding: '12px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 2px',
      }}
    >
      <CardContent>
        <Typography variant="body2" gutterBottom>
          <strong>Message:</strong> {message}
        </Typography>
        <Typography variant="body2">
          <strong>Start Index:</strong> {startIndex}
        </Typography>
        <Typography variant="body2">
          <strong>End Index:</strong> {endIndex}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DecodeResultCard;
