import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';

import StyledFinetunePage from '../StyledFinetunePage';
import axios from 'axios';
import ConfigSection, { ConfigData, ConfigTable } from './ConfigSection';
import DatasetSection from './DatasetSection';

type Pipeline = {
  pipeline_id: string;
  name: string;
  versions: Version[];
  is_default: boolean;
};

type Version = {
  pipeline_version_id: string;
  version: string;
  is_default: boolean;
};

const FinetunePage = () => {
  // 파이프라인 정보
  const [pipelineData, setPipelineData] = useState<Pipeline[] | null>(null);
  // config 정보
  const [configData, setConfigData] = useState<Record<string, ConfigData>>({});
  // 업데이트 된 config 정보
  const [updatedConfigData, setUpdatedConfigData] = useState<
    Record<string, ConfigData>
  >({});
  // 입력받은 데이터셋 이름
  const [modelName, setModelName] = useState<string>('');
  // 선택한 파이프라인
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline>();
  //선텍한 버전
  const [selectedVersion, setSelectedVersion] = useState<string>();
  // RTVC 여부
  const [isRtvc, setIsRtvc] = useState<boolean>(false);
  // 업로드 한 데이터셋
  const [datasetFileList, setDatasetFileList] = useState<File | undefined>();
  // 데이터셋 valid 여부
  const [isValid, setIsValid] = useState<boolean>(false);
  // 업로드 할 url
  const [uploadUrl, setUploadUrl] = useState<string>();
  // 결과 처리 중 여부
  const [isProcessing, setIsProcessing] = useState(false);

  const tunerBaseURL = process.env.REACT_APP_TUNER_API_HOST;

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  useEffect(() => {
    getPipelineData();
  }, []);

  // Initialize data based on whether it is RTVC or not
  useEffect(() => {
    setDatasetFileList(undefined);
    setModelName('');
  }, [isRtvc]);

  // get pipeline data from server
  const getPipelineData = async () => {
    try {
      const { data } = await axios.get(tunerBaseURL + '/v1/pipeline');
      const pipelines: Pipeline[] = data.pipelines;

      setPipelineData(pipelines);
    } catch (err) {
      toast.error(
        formatMessage({
          id: 'tuner.msg.error-pipeline',
          defaultMessage: 'Failed to retrieve the pipeline information.',
        })
      );
    }
  };

  // get config data from server
  const getConfigData = async () => {
    const chosen_pipeline = {
      name: selectedPipeline?.name,
      pipeline_id: selectedPipeline?.pipeline_id,
      version: selectedPipeline?.versions.find(
        (version) => version.version === selectedVersion
      ),
    };

    try {
      const { data } = await axios.post(
        `${tunerBaseURL}/v1/pipeline/${isRtvc ? 'rtvc' : 'cvc'}/args`,
        chosen_pipeline
      );

      setConfigData(data.data);
    } catch (err) {
      toast.error(
        formatMessage({
          id: 'tuner.msg.error-config',
          defaultMessage: 'Failed to retrieve the configuration information.',
        })
      );
    }
  };

  // set pipeline and version by is_default value
  useEffect(() => {
    const defaultPipeline = pipelineData?.find((p) => p.is_default);

    if (defaultPipeline) {
      setSelectedPipeline(defaultPipeline);

      const defaultVersion = defaultPipeline.versions.find((v) => v.is_default);

      if (defaultVersion) {
        setSelectedVersion(defaultVersion.version);
      }
    }
  }, [pipelineData]);

  // get config data after the pipeline has been selected
  useEffect(() => {
    if (selectedVersion) {
      getConfigData();
    }
  }, [selectedVersion]);

  // if the pipeline changes, update the version. ( Versions depend on the pipeline.)
  const onPipelineChange = (e: SelectChangeEvent<string>) => {
    const selectedPipeline = e.target.value;
    const newSelectedPipeline = pipelineData?.find(
      (p) => p.name === selectedPipeline
    );

    if (newSelectedPipeline) {
      setSelectedPipeline(newSelectedPipeline);
      setIsRtvc(newSelectedPipeline?.name.startsWith('rtvc-'));
      const defaultVersion = newSelectedPipeline.versions.find(
        (v) => v.is_default
      );
      if (defaultVersion) {
        setSelectedVersion(defaultVersion.version);
      }
    }
  };

  const onVersionChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value) {
      setSelectedVersion(e.target.value);
    }
  };

  // Get the S3 URL to upload the file if the zip file is valid.
  useEffect(() => {
    if (isValid && datasetFileList) {
      // set default model name
      if (!isRtvc) setModelName(datasetFileList.name.replace('.zip', ''));
      if (isRtvc) setModelName(datasetFileList.name.replace('.wav', ''));

      const fetchData = async () => {
        try {
          const { data } = await axios.get(
            tunerBaseURL + '/v1/dataset/upload_url?path=' + datasetFileList.name
          );

          setUploadUrl(data.url);
        } catch (err: any) {
          toast.error(err.response.data.message);
          setUploadUrl(undefined);
          setModelName('');
        }
      };

      fetchData();
    } else {
      setModelName('');
    }
  }, [isValid]);

  // Upload file to S3 server and then call launch finetune
  const onLaunchClick = async () => {
    if (uploadUrl && datasetFileList) {
      try {
        setIsProcessing(true);

        await uploadFile(uploadUrl, datasetFileList);

        callLaunch();
      } catch (err: any) {
        if (err.status === 'error') {
          toast.error(
            formatMessage({
              id: 'tuner.msg.error-launch',
              defaultMessage: 'Failed to launch finetune.',
            })
          );
        } else {
          toast.error(
            formatMessage({
              id: 'tuner.msg.error-upload',
              defaultMessage: 'Failed to upload the file.',
            })
          );
        }
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const uploadFile = async (uploadURL: string, datasetFileList: File) => {
    const backupAuth = axios.defaults.headers.common['Authorization'];

    delete axios.defaults.headers.common['Authorization'];

    // 성공 후 Authorization 키 복원
    return await axios
      .put(uploadURL, datasetFileList, {
        headers: {
          'Content-Type': 'application/zip',
        },
      })
      .finally(() => {
        axios.defaults.headers.common['Authorization'] = backupAuth;
      });
  };

  const callLaunch = async () => {
    const requestBodyData = makeRequestBody();

    try {
      await axios.post(
        `${tunerBaseURL}/v1/pipeline/${isRtvc ? 'rtvc' : 'cvc'}/launch`,
        requestBodyData
      );

      navigate('/tuner/history');
    } catch (err) {
      toast.error(
        formatMessage({
          id: 'tuner.msg.error-launch',
          defaultMessage: 'Failed to launch the finetune.',
        })
      );
    }
  };

  // make request body for launch request
  const makeRequestBody = () => {
    const chosen_pipeline = {
      name: selectedPipeline?.name,
      pipeline_id: selectedPipeline?.pipeline_id,
      version: selectedPipeline?.versions.find(
        (version) => version.version === selectedVersion
      ),
    };

    const finetuning_dataset = {
      dataset_name: modelName,
      path: uploadUrl,
    };

    const pipeline_args = {
      data: updatedConfigData,
    };

    for (const section in configData) {
      if (!pipeline_args.data[section]) {
        pipeline_args.data[section] = {};
      }
    }

    return {
      chosen_pipeline,
      finetuning_dataset,
      pipeline_args,
    };
  };

  const handleConfigDataUpdate = (
    section: string,
    updatedData: ConfigTable
  ) => {
    setUpdatedConfigData((prevConfigData: Record<string, ConfigData>) => ({
      [section]: {
        ...prevConfigData[section],
        [updatedData.config]: updatedData.value,
      },
    }));
  };

  useEffect(() => {
    setConfigData((prevConfigData: Record<string, ConfigData>) => {
      const mergedConfigData = { ...prevConfigData };

      for (const section in updatedConfigData) {
        mergedConfigData[section] = {
          ...prevConfigData[section],
          ...updatedConfigData[section],
        };
      }

      return mergedConfigData;
    });
  }, [updatedConfigData]);

  return (
    <StyledFinetunePage>
      <section>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Typography variant="h3">
            <FormattedMessage
              id="tuner.menu.finetune"
              defaultMessage="Finetune"
            />
          </Typography>
        </Stack>
      </section>
      <section>
        <div className="option">
          <div className="pipeline">
            <Typography variant="body1" component="span">
              <FormattedMessage
                id="tuner.finetune.pipeline"
                defaultMessage="PipeLine"
              />
            </Typography>
            <FormControl>
              <Select
                labelId="pipeline"
                value={selectedPipeline?.name ?? ''}
                onChange={onPipelineChange}
              >
                {pipelineData?.map((pipeline) => (
                  <MenuItem key={pipeline.name} value={pipeline.name}>
                    {pipeline.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="version">
            <Typography variant="body1" component="span">
              <FormattedMessage
                id="tuner.finetune.version"
                defaultMessage="Version"
              />
            </Typography>
            <FormControl>
              <Select
                labelId="version"
                value={selectedVersion ?? ''}
                onChange={onVersionChange}
              >
                {selectedPipeline?.versions.map((version) => (
                  <MenuItem key={version.version} value={version.version}>
                    {version.version}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </section>
      <DatasetSection
        onChangeValid={setIsValid}
        modelName={modelName}
        onChangeModelName={setModelName}
        datasetFileList={datasetFileList}
        onChangeDatasetFileList={setDatasetFileList}
        resetUploadUrl={() => setUploadUrl(undefined)}
        showStructure={!isRtvc}
      />
      <ConfigSection
        configData={configData}
        onConfigDataUpdate={handleConfigDataUpdate}
      />
      <section>
        <Button
          className="launch-button"
          variant="contained"
          size="large"
          fullWidth
          disabled={!isValid || !uploadUrl || !modelName || isProcessing}
          onClick={onLaunchClick}
          sx={{
            background: '#287578',
            '&:hover': {
              background: '#206366',
            },
          }}
        >
          {isProcessing ? (
            <>
              <CircularProgress
                style={{
                  marginRight: '8px',
                  width: '26px',
                  height: '26px',
                }}
                color="inherit"
              />
              <FormattedMessage
                id="tuner.msg.processing"
                defaultMessage="Processing..."
              />
            </>
          ) : (
            <FormattedMessage
              id="tuner.finetune.launch"
              defaultMessage="Launch"
            />
          )}
        </Button>
      </section>
    </StyledFinetunePage>
  );
};

export default FinetunePage;
